import React from "react";
import { Grid } from "@mui/material";
import kuva1 from "../KasvattajastaKuvat/1.jpg";
import kuva2 from "../KasvattajastaKuvat/2.jpg";
import kuva3 from "../KasvattajastaKuvat/3.jpg";
import kuva4 from "../KasvattajastaKuvat/4.jpg";
import kuva5 from "../KasvattajastaKuvat/5.jpg";
import kuva6 from "../KasvattajastaKuvat/6.jpg";
import "./ImageContainer.css";

const ImageInContainer = () => {
  return (
    <>
      <Grid container direction="row" style={{ padding: 5, display: "flex" }}>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva1}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva2}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva3}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva4}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva5}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva6}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ImageInContainer;
