import React from "react";
import { Grid } from "@mui/material";
import "./styles.css";
import kuva1 from "../Kuvat/ninniuusikuva2.jpg";
import kuva2 from "../Kuvat/ninniuusikuva1.jpg";
import kuva3 from "../Kuvat/ninnipaa.jpeg";
import kuva4 from "../Kuvat/evg.jpg";
import kuva5 from "../Kuvat/dna.jpg";
import ImageSlider from "../Components/ImageSlider";

const Koirat = () => {
  return (
    <div className="page-container">
      <Grid container>
        <Grid item xs={12}>
          <h1 className="ninni-etusivu-text">NINNI</h1>
          <h3 className="ninni-etusivu-alatext">JADELMIINAN WHISPERINGANGEL</h3>
        </Grid>
      </Grid>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
            xl: "row",
            lg: "row",
            sm: "colum",
          },
          padding: 5,
        }}
      >
        <Grid item xs={12} md={6} lg={6}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva1}
            alt="NinniXvilma"
            className="koirat-1-kuva"
          />
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva2}
            alt="NinniXvilma"
            className="koirat-2-kuva"
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{ color: "#e94aa1", textAlign: "center" }}
        >
          <Grid container direction="row">
            <Grid item xs={6}>
              <h3 className="koirat-h3-text-link">
                <a
                  className="koirat-h4-text"
                  target="_blank"
                  rel="noreferrer"
                  href="https://jalostus.kennelliitto.fi/frmKoira.aspx?RekNo=FI28750%2F21&R=88"
                >
                  Ninni koiranetissä
                </a>
              </h3>
              <h3 className="koirat-h3-text">Blue Merle</h3>
              <h3 className="koirat-h3-text">Narttu</h3>
              <h3 className="koirat-h3-text">Synt. 15.4.2021</h3>
              <h3 className="koirat-h3-text">Koko 39cm</h3>

              <h3
                style={{ marginBottom: "20px" }}
                className="koirat-h3-text-link"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="koirat-h4-text"
                  href="https://www.wisdompanel.com/app/s/51m7znh/highlights"
                >
                  MyDogDNA
                </a>
              </h3>

              <p className="koirat-p-text">
                <b className="koirat-b-text">MDRI1</b> Clear
              </p>
              <p className="koirat-p-text">
                <b className="koirat-b-text">VWD</b> Clear
              </p>
              <p className="koirat-p-text">
                <b className="koirat-b-text">PRA</b> Clear
              </p>
              <p className="koirat-p-text">
                <b className="koirat-b-text">CEA</b> Clear
              </p>
              <p className="koirat-p-text">
                <b className="koirat-b-text">DM</b> Carrier
              </p>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 10 }}>
              <b>Terveys</b>

              <h6 className="koirat-h6-text">Lonkat</h6>
              <p className="koirat-p-text">B/B</p>

              <h6 className="koirat-h6-text">Kyynärät</h6>
              <p className="koirat-p-text">0/0</p>

              <h6 className="koirat-h6-text">Polvet</h6>
              <p className="koirat-p-text">0/0</p>

              <h6 className="koirat-h6-text">Selkä</h6>
              <p className="koirat-p-text">VA0, LTV1</p>

              <h6 className="koirat-h6-text">Silmät</h6>
              <p className="koirat-p-text">
                Ei todettu perinnöllisiä silmäsairauksia
              </p>

              <h6 className="koirat-h6-text">Sydän</h6>
              <p className="koirat-p-text">Terve</p>

              <h6 className="koirat-h6-text">Purenta</h6>
              <p className="koirat-p-text">Saksipurenta, kaikki hampaat</p>

              <h6 className="koirat-h6-text">Sappi ultrattu puhtaaksi 7/23</h6>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" spacing={1} style={{ padding: 5 }}>
        <Grid item xs={6}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva3}
            alt="NinniXvilma"
            className="kasvattajasta-7"
          />
        </Grid>

        <Grid item xs={3}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva4}
            alt="NinniXvilma"
            className="kasvattajasta-1"
          />
        </Grid>

        <Grid item xs={3}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva5}
            alt="NinniXvilma"
            className="kasvattajasta-1"
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            color: "#e94aa1",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <p>
            Ninni on mun ensimmäinen koira ja täydellinen sellainen onkin.
            Tiesin jo ennen sen kotiutumista että hänestä tulee Ninni, hänet on
            nimetty muumihahmon näkymätön ninni mukaan. Ninni on ollut
            pikkupennusta asti reipas ja utelias.
          </p>
          <p>
            Nykyään Ninni on fiksu, rauhallinen, iloinen, helposti
            koulutettavissa ja motivoitavissa oleva kärsivällinen hyvin arjessa
            toimiva kotikoira. Ninni rakastaa tuttuja ihmisiä ja vielä enemmän
            tuttuja koirakavereita. Se tulee toimeen kaikkien koirien kanssa ja
            rakastaakin pemuta koirakavereiden kanssa koirapuistossa.
          </p>
          <p>
            Koskaan et saa maata rauhassa sohvalla jos Ninni on samassa talossa,
            Ninni rakastaa käpertyä kainaloon ja kuorruttaa märillä pusuilla.
            Ninni ei hauku sisällä, ulkona kutsuu kaverit leikkiin haukkumalla
            ja saattaa komentaa kavereita puistossa. Ninni on mutkaton ottaa
            mukaan mihin tahansa, kauppakeskukseen, ravintolaan, julkisiin tai
            vaikka veneilemään. Tuntemattomiin ihmisiin Ninni suhtautuu
            välinpitämättömästi, ulkona se ei paimenna pyöriä, skuutteja eikä
            autoja ja muiden koirien ohittaminen hihnassa sujuu hyvin.
          </p>
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: 10 }}>
        <ImageSlider />
      </Grid>
    </div>
  );
};

export default Koirat;
