import React from "react";
import { Grid } from "@mui/material";
import "./styles.css";

const Contact = () => {
  return (
    <div className="page-container">
      <Grid container>
        <Grid item xs={12}>
          <h1>Yhteystiedot</h1>
          <h3>Aivan Liian -kennel</h3>
        </Grid>

        <Grid xs={12} lg={12} style={{ marginBottom: "2rem" }}>
          <p>Vilma Nieminen</p>
          <p>Helsinki, Finland</p>
        </Grid>
        
        <Grid item lg={4} xs={4} style={{ flexDirection: "column", display: "flex" }}>
          <a href="mailto: aivanliiansheltit@gmail.com"><i className="fa fa-envelope"></i></a>
          <b className="yht-b">Sähköposti</b>
          <p className="yht-p">aivanliiansheltit@gmail.com</p>
        </Grid>

        <Grid item lg={4} xs={4} style={{ flexDirection: "column", display: "flex" }}>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ninnisheltti/"><i className="fab fa-instagram"></i></a>
          <b className="yht-b">Instagram</b>
          <p className="yht-p">@ninnisheltti</p>
        </Grid>

        <Grid item lg={4} xs={4} style={{ flexDirection: "column", display: "flex" }}>
          <a href="/yhteystiedot"><i className="fa fa-external-link"></i></a>
          <b className="yht-b">Linkkejä</b>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
