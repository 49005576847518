import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import NavBar from "./Components/NavBar";
import Kasvattajasta from "./Pages/Kasvattajasta";
import Etusivu from "./Pages/Etusivu";
import Contact from "./Pages/Contact";
import Kasvatus from "./Pages/Kasvatus";
import Koirat from "./Pages/Koirat";

const App = () => {
  const [classname, setClassname] = React.useState("App");

  const location = useLocation();

  React.useEffect(() => {
    setClassname(location.pathname);
  }, [location]);

  const generateRightStyle = (value) => {
    switch (value) {
      case "/kasvattajasta":
        return "App-kasvattajasta";
      case "/koirat":
        return "App-koirat";
      default:
        return "App";
    }
  };

  const styleName = generateRightStyle(classname);
  return (
    <div className={styleName}>
      <NavBar />
      <Routes>
        <Route path="/" element={<Etusivu />} />
        <Route path="/kasvattajasta" element={<Kasvattajasta />} />
        <Route path="/yhteystiedot" element={<Contact />} />
        <Route path="/kasvatus" element={<Kasvatus />} />
        <Route path="/koirat" element={<Koirat />} />
      </Routes>
    </div>
  );
};

export default App;
