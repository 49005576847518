import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./Swiper.css";
import kuva1 from "../SliderKuvat/sliderkuva1.png";
import kuva2 from "../SliderKuvat/kasvukuva2.jpg";
import kuva3 from "../SliderKuvat/kasvukuva3.jpg";
import kuva4 from "../SliderKuvat/kasvukuva4.jpg";
import kuva5 from "../SliderKuvat/kuva1.jpg";
import kuva6 from "../SliderKuvat/kuva2.jpg";
import kuva7 from "../SliderKuvat/kuva3.jpg";
import kuva8 from "../SliderKuvat/kuva4.jpg";
import kuva9 from "../SliderKuvat/kuva5.jpg";
import kuva10 from "../SliderKuvat/kuva6.jpg";
import kuva11 from "../SliderKuvat/kuva7.jpg";
import kuva12 from "../SliderKuvat/kuva8.jpg";
import kuva13 from "../SliderKuvat/kuva9.jpg";

// import required modules
import { Pagination } from "swiper/modules";
import { Grid } from "@mui/material";

const ImageSlider = () => {
  return (
    <Grid item xs={12} style={{ paddingLeft: 5, paddingRight: 5 }}>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={kuva1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva4} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva5} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva6} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva7} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva8} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva9} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva10} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva11} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva12} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={kuva13} alt="" />
        </SwiperSlide>
      </Swiper>
    </Grid>
  );
};

export default ImageSlider;
