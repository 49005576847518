import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const location = useLocation();

  const generateRightStyle = (value) => {
    switch (value) {
      case "/kasvattajasta":
        return "navbar-logo-kasvattajasta";
      case "/koirat":
        return "navbar-logo-koirat";
      default:
        return "navbar-logo";
    }
  };

  const generateNavitemStyle = (value) => {
    switch (value) {
      case "/kasvattajasta":
        return "nav-links-kasvattajasta";
      case "/koirat":
        return "nav-links-koirat";
      default:
        return "nav-links";
    }
  };

  const styleName = generateNavitemStyle(location.pathname);
  const styleNameLogo = generateRightStyle(location.pathname);

  return (
    <>
      <nav className="navbar">
        <Link to="/" className={styleNameLogo} onClick={closeMobileMenu}>
          AIVAN LIIAN
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i
            style={{
              color:
                location.pathname === "/koirat" && !click
                  ? "#e94aa1"
                  : undefined,
            }}
            className={click ? "fas fa-times" : "fas fa-bars"}
          />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/" className={styleName} onClick={closeMobileMenu}>
              Etusivu
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/kasvattajasta"
              className={styleName}
              onClick={closeMobileMenu}
            >
              Kasvattajasta
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/koirat" className={styleName} onClick={closeMobileMenu}>
              Koirat
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/kasvatus"
              className={styleName}
              onClick={closeMobileMenu}
            >
              Kasvatus
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/yhteystiedot"
              className={styleName}
              onClick={closeMobileMenu}
            >
              Yhteystiedot
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
