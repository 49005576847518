import React from "react";
import "./styles.css";
import { Grid } from "@mui/material";

const Kasvatus = () => {
  return (
    <div className="page-container">
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <h1>Kasvatus</h1>
        </Grid>

        <Grid item xs={10} lg={7}>
          <p>Kasvatan ensisijaisesti perheenjäseniä, mutta pennun ostajan odotan sitoutuvan teettämään pennulle viralliset terveystarkastukset ainakin silmien, lonkkien, kyynärien, polvien ja selän osalta.
             Myyn pennut perheenjäseniksi aktiivisiin koteihin, joissa niitä tullaan rakastamaan niiden koko elämän ajan. Pennun ostajilta toivon yhteistyötä ja yhteydenpitoa koko koiran eliniän, niin hyvinä kuin huonoinakin hetkinä.</p>

          <p>Aivan liian -kennelin pennut luovutetaan aikaisintaan 8 viikon ikäisinä rekisteröityinä, eläinlääkärin tarkastamina, sirutettuina, silmäpeilattuina ja useaan kertaan madotettuina. En kasvata pentuja kenneltiloissa vaan pennut kasvavat meillä arjen
             keskellä tottuen arjen ääniin, erilaisiin ihmisiin, eläimiin, alustoihin ja ympäristöihin. Pennun mukaan saat pentupakkauksen, pennun paperit ja kasvattajan tuen. </p>
        
          <p>Mikäli olet kiinnostunut Aivan liian -kennelin pennusta, laitathan minulle sähköpostia (yhteystiedot löydät yhteystiedot -sivulta) ja kerrot hieman itsestäsi, millaisen kodin
             tarjoaisit pennulle ja millaista pentua etsit.</p>

          <p>Valitsen pennuille sopivat kodit vasta tapaamisen jälkeen eikä minulta voi varata pentua ennen tapaamista. En myöskään pidä varauslistoja pennuista. Jokainen pentu on yksilö, joka ansaitsee
             itselleen sopivan kodin.</p>

          <p>Suosittelen jokaista shetlanninlammaskoirasta kiinnostunutta tutustumaan rotuyhdistyksen nettisivuihin, joista saa paljon kattavaa tietoa rodusta.</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Kasvatus;
