import React from "react";
import { HashRouter as Router } from "react-router-dom";
import "./App.css";
import App from "./App";

const AivanliianApp = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AivanliianApp;
