import React from "react";
import Ninni from "../Kuvat/ninnipng.png";
import Ninni2 from "../Kuvat/etusivu_ninni.jpg";
import { Grid } from "@mui/material";
import "./styles.css";

const Etusivu = () => {
  return (
    <div className="page-container">
      <Grid container className="etusivu-banner" direction="column">
        <Grid
          item
          xs={12}
          className="banneri-kuva"
          style={{ marginTop: "-8vh" }}
        >
          <img
            style={{
              height: "auto",
              width: "100%",
              maxHeight: "700px",
              maxWidth: "700px",
            }}
            src={Ninni}
            alt="Girl in a jacket"
          />
        </Grid>
        <Grid className="etusivu-text" item xs={12}>
          <p className="bold-text">
            PIENIMUOTOISTA SHETLANNINLAMMASKOIRIEN KASVATUSTA
          </p>
        </Grid>
        <Grid item xs={12} style={{ margin: "10px" }}>
          <img
            style={{
              height: "auto",
              width: "100%",
              maxHeight: "1000px",
              maxWidth: "970px",
            }}
            src={Ninni2}
            alt="asdasd"
            className="kuva2"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Etusivu;
