import React from "react";
import kuva1 from "../Kuvat/kasvattajasta1.jpg";
import { Grid } from "@mui/material";
import "./styles.css";
import ImageInContainer from "../Components/ImageInContainer";
import alakuva1 from "../KasvattajastaKuvat/alakuva1.jpg";
import alakuva2 from "../KasvattajastaKuvat/alakuva2.jpg";
import alakuva3 from "../KasvattajastaKuvat/alakuva3.jpg";

const Kasvattajasta = () => {
  return (
    <div className="page-container">
      <Grid
        container
        className="kasvattajasta-vasen"
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
            xl: "row",
            lg: "row",
            sm: "column",
          },
          padding: "20px",
        }}
      >
        <Grid item xs={12} md={8}>
          <h1 className="kasvattajasta-title">Kasvattajasta</h1>
          <p className="p-text-kasvattajasta">
            Moikka! Olen Vilma Nieminen, aloitteleva shetlanninlammaskoirien kasvattaja.
          </p>
          <p className="p-text-kasvattajasta">
            Olen aina ollut eläinrakas, mutta perheeseeni ei koskaan voitu ottaa
            lemmikkejä perheenjäsenen allergioiden takia. Vuonna 2020 elämääni
            kuului aivan mahtava australianpaimenkoira Yoda, jonka ansiosta sain
            vihdoin ensikosketuksen koiramaailmaan eikä siitä ole enää ollut
            paluuta.
          </p>
          <p className="p-text-kasvattajasta">
            Pääsin kunnolla mukaan koiramaailmaan vuonna 2021 kun sain
            ensimmäisen oman koirani Jadelmiina Whisperingangelin,
            tuttavallisemmin Ninnin. Elämä Ninnin kanssa on opettanut minulle
            paljon ja tiesin alusta asti, että sheltti on minulle se oikea rotu.
          </p>
          {/* kuva esittely */}
          <Grid item lg={12}>
            <ImageInContainer />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
        >
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={kuva1}
            alt="NinniXvilma"
            className="kasvattajasta-1"
          />
          <p className="p-text-kasvattajasta">
            Vuoden 2022 alussa aloin salaa haaveilla kasvattamisesta ja kävinkin
            kasvattajakurssin tammikuussa 2023. Kennelnimi “Aivan liian”
            myönnettiin minulle 4/2023.
          </p>
          <p className="p-text-kasvattajasta">
            Kasvatustyössäni haluan panostaa luonteeseen, terveyteen ja siihen
            että kasvattini voivat sekä menestyä näyttelyissä että kisakentillä.
            Tavoitteeni on kuitenkin ennen kaikkea että kasvatistani saa
            pitkäikäisen perheenjäsenen. Aloittelevana kasvattajana olen
            erittäin kiinnostunut oppimaan lisää ja olenkin itsenäisesti sekä
            useiden luentojen että muiden kasvattajien avulla opiskellut paljon
            kasvattamisesta ja koirista yleisesti.
          </p>
        </Grid>
      </Grid>
      <Grid container style={{ padding: 10 }}>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={alakuva1}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={alakuva2}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
        <Grid item xs={4} padding={1}>
          <img
            style={{
              height: "auto",
              width: "100%",
            }}
            src={alakuva3}
            alt="NinniXvilma"
            className="kuva-kollaasi"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Kasvattajasta;
